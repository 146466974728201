import React, { useState, useEffect } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Steps } from 'primereact/steps';
import { Dialog } from 'primereact/dialog';
import { RadioButton } from 'primereact/radiobutton';
import {
    useSendNewsletter,
    useTestSendNewsletter,
    useSendVeggiesNewsletter,
    useTestSendVeggiesNewsletter,
    useSendTestFestiveNewsletter,
    useSendFestiveNewsletter
} from "./services/DataServices";


const NewsletterForm = () => {
    const [url, setUrl] = useState('https://www.lotusmarkt.com/wp-json/wc/v3/products');
    const [numberOfProducts, setNumberOfProducts] = useState('');
    const [subject, setSubject] = useState('');
    const [taskId, setTaskId] = useState(null);
    const [currentStep, setCurrentStep] = useState(0);
    const [confirmationVisible, setConfirmationVisible] = useState(false);
    const [confirmationType, setConfirmationType] = useState('');
    const [newsletterType, setNewsletterType] = useState('latestProducts'); // default to 'latestProducts'

    const sendNewsletterM = useSendNewsletter();
    const sendTestNewsletterM = useTestSendNewsletter();
    const sendNewsletterVeggiesM = useSendVeggiesNewsletter();
    const sendTestNewsletterVeggiesM = useTestSendVeggiesNewsletter();
    const sendNewsletterFestiveM = useSendFestiveNewsletter();
    const sendTestNewsletterFestiveM = useSendTestFestiveNewsletter();

    const API_URL = "https://app-membership.onrender.com"
    // const API_URL = "http://127.0.0.1:8000"


    const startNewsletter = (url, numberOfProducts, subject) => {

        if (newsletterType === 'latestProducts') {
            sendNewsletterM.mutate({ url, number_of_products: parseInt(numberOfProducts), subject }, {
                onSuccess: (data) => {
                    setTaskId(data.task_id);
                    setCurrentStep(0);
                },
                onError: (response) => {
                    console.error("Error sending newsletter:", response);
                },
            });
        } else if(newsletterType === 'festive') {
            sendNewsletterFestiveM.mutate({ url, number_of_products: parseInt(numberOfProducts), subject }, {
                onSuccess: (data) => {
                    console.log(data)
                    setTaskId(data.task_id);
                    setCurrentStep(0);
                },
                onError: (response) => {
                    console.error("Error sending newsletter:", response);
                },
            });
        }
        else {
            sendNewsletterVeggiesM.mutate({ url, number_of_products: parseInt(numberOfProducts), subject }, {
                onSuccess: (data) => {
                    setTaskId(data.task_id);
                    setCurrentStep(0);
                },
                onError: (response) => {
                    console.error("Error sending newsletter:", response);
                },
            });
        }

    }

    const startTestNewsletter = (url, numberOfProducts, subject) => {

        if (newsletterType === 'latestProducts') {
            sendTestNewsletterM.mutate({ url, number_of_products: parseInt(numberOfProducts), subject }, {
                onSuccess: (data) => {
                    console.log(data)
                    setTaskId(data.task_id);
                    setCurrentStep(0);
                },
                onError: (response) => {
                    console.error("Error sending newsletter:", response);
                },
            });
        } else if(newsletterType === 'festive') {
            sendTestNewsletterFestiveM.mutate({ url, number_of_products: parseInt(numberOfProducts), subject }, {
                onSuccess: (data) => {
                    console.log(data)
                    setTaskId(data.task_id);
                    setCurrentStep(0);
                },
                onError: (response) => {
                    console.error("Error sending newsletter:", response);
                },
            });
        }
        else {
            sendTestNewsletterVeggiesM.mutate({ url, number_of_products: parseInt(numberOfProducts), subject }, {
                onSuccess: (data) => {
                    console.log(data)
                    setTaskId(data.task_id);
                    setCurrentStep(0);
                },
                onError: (response) => {
                    console.error("Error sending newsletter:", response);
                },
            });
        }

    }
    useEffect(() => {
        if (!taskId) return;

        const eventSource = new EventSource(`${API_URL}/status_updates/${taskId}`);

        eventSource.onmessage = (event) => {
            try {
                console.log(event)
                const statusMessage = event.data;
                console.log(statusMessage)

                switch (statusMessage) {
                    case 'Fetching products...':
                        setCurrentStep(1);
                        break;
                    case 'Products fetched. Rendering HTML...':
                        setCurrentStep(2);
                        break;
                    case 'Rendering HTML...':
                        setCurrentStep(3);
                        break;
                    case 'Sending emails...':
                        setCurrentStep(4);
                        break;
                    case 'Done':
                        setCurrentStep(5);
                        eventSource.close();
                        break;
                    default:
                        setCurrentStep(0);
                }
            } catch (e) {
                console.error("Error parsing event data:", e);
            }
        };

        eventSource.onerror = (error) => {
            console.error("EventSource failed:", error);
            eventSource.close();
        };

        return () => {
            eventSource.close();
        };
    }, [taskId]);


    const items = [
        { label: 'Pending' },
        { label: 'Fetching products' },
        { label: 'Products fetched' },
        { label: 'Rendering HTML' },
        { label: 'Sending emails' },
        { label: 'Done' }
    ];
    const startNewsletterAfterConfirmation = () => {
        setConfirmationVisible(false);
        if (confirmationType === 'test') {
            startTestNewsletter(url, numberOfProducts, subject);
        } else if (confirmationType === 'actual') {
            startNewsletter(url, numberOfProducts, subject);
        }
    };

    const confirmStartTestNewsletter = () => {
        setConfirmationType('test');
        setConfirmationVisible(true);
    };

    const confirmStartNewsletter = () => {
        setConfirmationType('actual');
        setConfirmationVisible(true);
    };
    return (
        <div className="p-grid p-fluid">
            <div className="p-col-12">
                <h2>Send Newsletter</h2>
            </div>
            <div className="p-col-12">
                <h2>Progress Bar</h2>
            </div>
            <div className="p-col-12 pb-4">
                <Steps model={items} activeIndex={currentStep} readOnly={true}/>
            </div>

            <div className="p-col-12 p-md-4">
                <InputText value={url} onChange={(e) => setUrl(e.target.value)} placeholder="URL"/>
            </div>
            <div className="p-col-12 p-md-4">
                <InputText value={numberOfProducts} onChange={(e) => setNumberOfProducts(e.target.value)}
                           placeholder="Number of Products"/>
            </div>
            <div className="p-col-12 p-md-4">
                <InputText value={subject} onChange={(e) => setSubject(e.target.value)} placeholder="Subject"/>
            </div>
            <div className="p-col-12">
                <h3>Select Newsletter Type</h3>
                <div className="p-field-radiobutton pb-4">
                    <RadioButton inputId="latestProducts" name="newsletterType" value="latestProducts"
                                 onChange={(e) => setNewsletterType(e.value)}
                                 checked={newsletterType === 'latestProducts'}/>
                    <label className="m-2" htmlFor="latestProducts">Latest Products</label>
                </div>
                <div className="p-field-radiobutton pb-4">
                    <RadioButton inputId="freshVeggies" name="newsletterType" value="freshVeggies"
                                 onChange={(e) => setNewsletterType(e.value)}
                                 checked={newsletterType === 'freshVeggies'}/>
                    <label className="m-2" htmlFor="freshVeggies">Fresh Veggies</label>
                </div>
                <div className="p-field-radiobutton pb-4">
                    <RadioButton inputId="festive" name="newsletterType" value="festive"
                                 onChange={(e) => setNewsletterType(e.value)}
                                 checked={newsletterType === 'festiveItems'}/>
                    <label className="m-2" htmlFor="festiveItems">Festive Items</label>
                </div>
            </div>
            <div className="formgrid grid pt-4">
                <div className="field col">
                    <Button label="Send Test Newsletter" className="p-button-danger"
                            onClick={confirmStartTestNewsletter}/>
                </div>
                <div className="field col">
                    <Button label="Send Newsletter" className="p-button-success" onClick={confirmStartNewsletter}/>
                </div>
            </div>


            <Dialog visible={confirmationVisible} onHide={() => setConfirmationVisible(false)}
                    breakpoints={{'960px': '75vw', '640px': '100vw'}} style={{width: '50vw'}} header="Confirmation">
                <div className="confirmation-content">
                    <p>Are you sure you want to start
                        the {confirmationType === 'test' ? 'test' : 'actual'} newsletter?</p>
                </div>
                <div className="p-dialog-footer">
                    <Button label="Yes" icon="pi pi-check" onClick={startNewsletterAfterConfirmation}/>
                    <Button label="No" icon="pi pi-times" onClick={() => setConfirmationVisible(false)}
                            className="p-button-text"/>
                </div>
            </Dialog>

        </div>


    );
};

export default NewsletterForm;
