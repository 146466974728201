import React, { useState, useEffect, useRef, useMemo } from "react";
import { classNames } from "primereact/utils";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { InputTextarea } from "primereact/inputtextarea";
import { InputNumber } from "primereact/inputnumber";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { Tag } from "primereact/tag";
import moment from "moment";
import { FileUpload } from "primereact/fileupload";
import {
  useGetData,
  useCreateNew,
  useUpdate,
  useDeleteEntry,
} from "./services/SaleServices";
import keycloak from "../keycloak";
import { Avatar } from "primereact/avatar";
import TotalCard from "./totalCard";
import { Accordion, AccordionTab } from "primereact/accordion";

function ZAbschlagManagementPortal() {
  let emptyItem = {
    date: null,
    sale: 0.0,
    saleActual: 0.0,
    bar: 0.0,
    barActual: 0.0,
    card: 0.0,
    cardActual: 0.0,
    bankTransfer: 0.0,
    bankTransferActual: 0.0,
    paypal: 0.0,
    paypalActual: 0.0,
    out: 0.0,
    description: "",
    file: "",
    status: 0,
    isFieldEdited: {},
  };

  const getData = useGetData();
  const createNew = useCreateNew();
  const updateEntry = useUpdate();
  const deleteEntry = useDeleteEntry();

  const [products, setProducts] = useState(null);
  const [productDialog, setProductDialog] = useState(false);
  const [deleteProductDialog, setDeleteProductDialog] = useState(false);
  const [deleteProductsDialog, setDeleteProductsDialog] = useState(false);
  const [product, setProduct] = useState(emptyItem);
  const [selectedProducts, setSelectedProducts] = useState(null);
  const [submitted, setSubmitted] = useState(false);
  const [globalFilter, setGlobalFilter] = useState(null);

  const [isEditing, setisEditing] = useState(false);

  const [totalSaleAmount, setTotalSaleAmount] = useState(0);
  const [totalCashAmount, setTotalCashAmount] = useState(0);
  const [totalCardAmount, setTotalCardAmount] = useState(0);
  const [totalBtAmount, setTotalBtAmount] = useState(0);
  const [totalPaypalAmount, setTotalPaypalAmount] = useState(0);
  const [totalOutAmount, setTotalOutAmount] = useState(0);

  const [totalSaleAmountO, setTotalSaleAmountO] = useState(0);
  const [totalCashAmountO, setTotalCashAmountO] = useState(0);
  const [totalCardAmountO, setTotalCardAmountO] = useState(0);
  const [totalBtAmountO, setTotalBtAmountO] = useState(0);
  const [totalPaypalAmountO, setTotalPaypalAmountO] = useState(0);

  const [totalSaleAmountDiff, setTotalSaleAmountDiff] = useState(0);
  const [totalCashAmountDiff, setTotalCashAmountDiff] = useState(0);
  const [totalCardAmountDiff, setTotalCardAmountDiff] = useState(0);
  const [totalBtAmountDiff, setTotalBtAmountDiff] = useState(0);
  const [totalPaypalAmountDiff, setTotalPaypalAmountDiff] = useState(0);

  const [disabledDates, setDisabledDates] = useState([]); // Months are zero-based (11 represents December)

  // Calculate differences and set in difference totals
  const calculateDifferences = () => {
    setTotalSaleAmountDiff((totalSaleAmountO - totalSaleAmount).toFixed(2));
    setTotalCashAmountDiff((totalCashAmountO - totalCashAmount).toFixed(2));
    setTotalCardAmountDiff((totalCardAmountO - totalCardAmount).toFixed(2));
    setTotalBtAmountDiff((totalBtAmountO - totalBtAmount).toFixed(2));
    setTotalPaypalAmountDiff((totalPaypalAmountO - totalPaypalAmount).toFixed(2));
  };

  useEffect(() => {
    calculateDifferences();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalSaleAmount, totalCashAmount, totalCardAmount, totalBtAmount, totalPaypalAmount]);
  


  const calculateTotal = (data, property) => {
    const total = data?.reduce((total, item) => {
      return total + item[property];
    }, 0);

    return parseFloat(total?.toFixed(2)); // Limit to 2 decimal places
  };

  const calculateAndSetTotals2 = (data, properties) => {
    const newData = data;

    const totals = properties.reduce((acc, property) => {
      const total = calculateTotal(newData, property);
      acc[property] = total;
      return acc;
    }, {});

    // Set state for each total dynamically
    Object.entries(totals).forEach(([property, value]) => {
      switch (property) {
        case "saleActual":
          setTotalSaleAmount(value);
          break;
        case "barActual":
          setTotalCashAmount(value);
          break;
        case "cardActual":
          setTotalCardAmount(value);
          break;
        case "bankTransferActual":
          setTotalBtAmount(value);
          break;
        case "paypalActual":
          setTotalPaypalAmount(value);
          break;
        case "out":
          setTotalOutAmount(value);
          break;
        // Add cases for other properties as needed
        case "sale":
          setTotalSaleAmountO(value);
          break;
        case "bar":
          setTotalCashAmountO(value);
          break;
        case "card":
          setTotalCardAmountO(value);
          break;
        case "bankTransfer":
          setTotalBtAmountO(value);
          break;
        case "paypal":
          setTotalPaypalAmountO(value);
          break;
        default:
          break;
      }
    });
  };

  // Usage
  const propertiesToCalculate2 = [
    "saleActual",
    "barActual",
    "cardActual",
    "bankTransferActual",
    "paypalActual",
    "out",
    "sale",
    "bar",
    "card",
    "bankTransfer",
    "paypal",
  ];

  const isUserGroupMember =
    keycloak.authenticated && keycloak.hasRealmRole("User");

  const getLastTwoEntriesById = () => {
    let dataToDisplay = getData.data?.result || [];

    // Sorting the data by id in descending order
    dataToDisplay = dataToDisplay.sort((a, b) => b.id - a.id);

    // Extracting the last two entries based on id if user is a group member
    if (isUserGroupMember) {
      dataToDisplay = dataToDisplay.slice(0, 2);
    }

    return dataToDisplay;
  };

  let showData = getLastTwoEntriesById();

  const [dateFilter, setDateFilter] = useState({
    fromDate: null,
    toDate: null,
  });
  const [filteredData, setFilteredData] = useState(showData); // Store filtered data

  const memoizedPropertiesToCalculate = useMemo(() => {
    // Usage
    const propertiesToCalculate = [
      "saleActual",
      "barActual",
      "cardActual",
      "bankTransferActual",
      "paypalActual",
      "out",
      "sale",
      "bar",
      "card",
      "bankTransfer",
      "paypal",
    ];

    return propertiesToCalculate;
  }, []);

  // Update filteredData when the data changes
  useEffect(() => {
    // Disable dates new 14.12.2023
    const getDisabledDates = () => {
      const disabledDateStrings = getData.data?.result.map((item) => item.date);
      const disabledDates = disabledDateStrings?.map((dateString) => {
        const [year, month, day] = dateString.split("-").map(Number);
        return new Date(year, month - 1, day); // Months are zero-indexed in JavaScript Dates
      });
      return disabledDates;
    };

    const calculateAndSetTotals = (data, properties) => {
      const newData = data;

      const totals = properties.reduce((acc, property) => {
        const total = calculateTotal(newData, property);
        acc[property] = total;
        return acc;
      }, {});

      // Set state for each total dynamically
      Object.entries(totals).forEach(([property, value]) => {
        switch (property) {
          case "saleActual":
            setTotalSaleAmount(value);
            break;
          case "barActual":
            setTotalCashAmount(value);
            break;
          case "cardActual":
            setTotalCardAmount(value);
            break;
          case "bankTransferActual":
            setTotalBtAmount(value);
            break;
          case "paypalActual":
            setTotalPaypalAmount(value);
            break;
          case "out":
            setTotalOutAmount(value);
            break;
          // Add cases for other properties as needed
          case "sale":
          setTotalSaleAmountO(value);
          break;
        case "bar":
          setTotalCashAmountO(value);
          break;
        case "card":
          setTotalCardAmountO(value);
          break;
        case "bankTransfer":
          setTotalBtAmountO(value);
          break;
        case "paypal":
          setTotalPaypalAmountO(value);
          break;
        default:
          break;
        }
      });
    };

    setFilteredData(showData);
    calculateAndSetTotals(showData, memoizedPropertiesToCalculate);
    let datesdisabled = getDisabledDates();
    setDisabledDates(datesdisabled);
  }, [getData.data?.result, showData, memoizedPropertiesToCalculate]);

  const applyDateFilter = () => {
    if (dateFilter.fromDate && dateFilter.toDate) {
      // Filter the DataTable data based on the date range
      const filtered = showData.filter((item) => {
        const itemDate = new Date(item.date);
        return itemDate >= dateFilter.fromDate && itemDate <= dateFilter.toDate;
      });

      // Set the filtered data to display in the DataTable
      setFilteredData(filtered);
      calculateAndSetTotals2(filtered, propertiesToCalculate2);
    } else {
      // If no date filter is applied, display the entire dataset
      setFilteredData(showData);
      calculateAndSetTotals2(showData, propertiesToCalculate2);
    }
  };

  const clearDateFilter = () => {
    // Reset the dateFilter state
    setDateFilter({ fromDate: null, toDate: null });

    // Set filteredData back to the entire dataset
    setFilteredData(showData);

    calculateAndSetTotals2(showData, propertiesToCalculate2);
  };

  const toast = useRef(null);
  const dt = useRef(null);

  const formatCurrency = (value) => {
    return value.toLocaleString("en-US", {
      style: "currency",
      currency: "EUR",
    });
  };
  const statusOptions = [
    { name: "Open", value: 0 },
    { name: "Closed", value: 1 },
  ];

  const openNew = () => {
    setProduct(emptyItem);
    setSubmitted(false);
    setProductDialog(true);
  };

  const hideDialog = () => {
    setSubmitted(false);
    setProductDialog(false);
    setisEditing(false);
  };

  const hideDeleteProductDialog = () => {
    setDeleteProductDialog(false);
  };

  const hideDeleteProductsDialog = () => {
    setDeleteProductsDialog(false);
  };

  const invoiceUploadHandler = ({ files }) => {
    const [file] = files;
    uploadInvoice(file);
  };

  const uploadInvoice = async (file) => {
    product.file = file;
    toast.current.show({
      severity: "info",
      summary: "Success",
      detail: "File Uploaded",
    });
    // const headers={'Content-Type': file.type}
    // const response = await axios.post("http://127.0.0.1:8000/upload", formData)
    // console.log(response)
  };

  const saveProduct = () => {
    setSubmitted(true);
    if (product.date && product.sale) {
      // let _products = [...products];
      let _product = { ...product };
      if (product.id) {
        if (typeof _product.date !== "string") {
          _product.date = moment(_product.date).format("YYYY-MM-DD");
        }
        if (_product.bar !== 0 && _product.barActual === 0) {
          _product.barActual = _product.bar;
        }
        if (_product.sale !== 0 && _product.saleActual === 0) {
          _product.saleActual = _product.sale;
        }
        if (_product.card !== 0 && _product.cardActual === 0) {
          _product.cardActual = _product.card;
        }
        if (_product.bankTransfer !== 0 && _product.bankTransferActual === 0) {
          _product.bankTransferActual = _product.bankTransfer;
        }
        if (_product.paypal !== 0 && _product.paypalActual === 0) {
          _product.paypalActual = _product.paypal;
        }
        updateEntry.mutate(
          {
            id: _product.id,
            data: _product,
          },
          {
            onSuccess: (response) => {
              toast.current.show({
                severity: "success",
                summary: "Successful",
                detail: response.message,
                life: 3000,
              });
            },
            onError: (response) => {
              toast.current.show({
                severity: "error",
                summary: "Error",
                detail: response.response.data.detail,
                life: 3000,
              });
            },
          }
        );
        setisEditing(false);
      } else {
        _product.idLink = createId();
        if (_product.date) {
          _product.date = moment(_product.date).format("YYYY-MM-DD");
        }
        if (_product.bar !== 0 && _product.barActual === 0) {
          _product.barActual = _product.bar;
        }
        if (_product.sale !== 0 && _product.saleActual === 0) {
          _product.saleActual = _product.sale;
        }
        if (_product.card !== 0 && _product.cardActual === 0) {
          _product.cardActual = _product.card;
        }
        if (_product.bankTransfer !== 0 && _product.bankTransferActual === 0) {
          _product.bankTransferActual = _product.bankTransfer;
        }
        if (_product.paypal !== 0 && _product.paypalActual === 0) {
          _product.paypalActual = _product.paypal;
        }
        createNew.mutate(_product, {
          onSuccess: (response) => {
            toast.current.show({
              severity: "success",
              summary: "Successful",
              detail: response.message,
              life: 3000,
            });
          },
          onError: (response) => {
            toast.current.show({
              severity: "error",
              summary: "Error",
              detail: response.response.data.detail,
              life: 3000,
            });
          },
        });
        setProductDialog(false);
        setProduct(emptyItem);
        setisEditing(false);
      }
    }
  };

  const editProduct = (product) => {
    setisEditing(true);
    if (product.status === "Open") {
      product.status = 0;
    }
    if (product.status === "Closed") {
      product.status = 1;
    }
    // Adding isFieldEdited field dynamically
    product.isFieldEdited = {};
    setProduct({ ...product });
    setProductDialog(true);
  };

  const confirmDeleteProduct = (product) => {
    setProduct(product);
    setDeleteProductDialog(true);
  };

  const deleteProduct = () => {
    // let _products = products.filter(val => val.id !== product.id);
    deleteEntry.mutate(product.id, {
      onSuccess: (response) => {
        toast.current.show({
          severity: "success",
          summary: "Successful",
          detail: response.message,
          life: 3000,
        });
      },
      onError: (response) => {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: response.message,
          life: 3000,
        });
      },
    });
    setDeleteProductDialog(false);
  };

  const createId = () => {
    let id = "";
    let chars =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    for (let i = 0; i < 5; i++) {
      id += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    return id;
  };

  const exportCSV = () => {
    dt.current.exportCSV();
  };

  const confirmDeleteSelected = () => {
    setDeleteProductsDialog(true);
  };

  const deleteSelectedProducts = () => {
    let _products = products.filter((val) => !selectedProducts.includes(val));
    setProducts(_products);
    setDeleteProductsDialog(false);
    setSelectedProducts(null);
    toast.current.show({
      severity: "success",
      summary: "Successful",
      detail: "Products Deleted",
      life: 3000,
    });
  };

  const onStatusChange = (e) => {
    let _product = { ...product };
    _product["status"] = e.value;
    setProduct(_product);
  };

  const onInputChange = (e, name) => {
    const val = (e.target && e.target.value) || "";
    let _product = { ...product };
    _product[`${name}`] = val;
    setProduct(_product);
  };

  const onInputNumberChange = (e, name) => {
    const val = e.value || 0;
    let _product = { ...product };
    _product[`${name}`] = val

    setProduct(_product);
  };

  const onInputNumberAllChange = (e, fieldName) => {
    const val = e.value || 0;

    let _product = { ...product };
    _product[`${fieldName}`] = val;
    _product.isFieldEdited[`${fieldName}`] = true;

    setProduct(_product);
  };

  const onDateChange = (e, name) => {
    let _product = { ...product };
    _product[`${name}`] = e.value;
    setProduct(_product);
  };

  const statusDisplay = (rowData) => {
    if (rowData.status === 0) {
      rowData.status = "Open";
    }
    if (rowData.status === 1) {
      rowData.status = "Closed";
    }
    return <Tag value={rowData.status} severity={getSeverity(rowData)}></Tag>;
  };

  const uploadedFileDisplay = (rowData) => {
    if (rowData.file) {
      return (
        <Button
          label="View"
          link
          onClick={() =>
            window.open(
              "http://admin.srivayuputra.de/content/zabschalg/" + rowData.file,
              "_blank"
            )
          }
        />
      );
    }
  };

  // Calculate saleActual and format it to 2 decimal places
  const calculateSaleActual = () => {
    const calculatedValue =
        product.barActual +
        product.cardActual +
        product.bankTransferActual +
        product.paypalActual;

    // Round to 2 decimal places
    return parseFloat(calculatedValue.toFixed(2));
  };


  const getSeverity = (rowData) => {
    switch (rowData.status) {
      case "Closed":
        return "success";

      // case 'LOWSTOCK':
      //     return 'warning';

      case "Open":
        return "danger";

      default:
        return null;
    }
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <Button
          icon="pi pi-pencil"
          className="p-button-rounded p-button-success mr-2"
          onClick={() => editProduct(rowData)}
          disabled={isUserGroupMember}
        />
        <Button
          icon="pi pi-trash"
          className="p-button-rounded p-button-danger"
          onClick={() => confirmDeleteProduct(rowData)}
          disabled={isUserGroupMember}
        />
      </React.Fragment>
    );
  };

  const header = (
    <div className="flex flex-column md:flex-row md:align-items-center justify-content-between">
      <span className="p-input-icon-left w-full md:w-auto">
        <i className="pi pi-search" />
        <InputText
          type="search"
          onInput={(e) => setGlobalFilter(e.target.value)}
          placeholder="Search..."
          className="w-full lg:w-auto"
        />
      </span>
      <div className="mt-3 md:mt-0 flex justify-content-end">
        <div className="mt-2 text-center">
          <span className="mr-2">Date Range Filter</span>
        </div>
        <Calendar
          value={dateFilter.fromDate}
          onChange={(e) => setDateFilter({ ...dateFilter, fromDate: e.value })}
          showIcon
          className="mr-2"
          placeholder="fromDate"
        />
        <Calendar
          value={dateFilter.toDate}
          onChange={(e) => setDateFilter({ ...dateFilter, toDate: e.value })}
          showIcon
          className="mr-2"
          placeholder="toDate"
        />
        <Button
          icon="pi pi-check"
          className="mr-2 p-button-rounded"
          onClick={applyDateFilter}
          tooltip="Apply"
          tooltipOptions={{ position: "bottom" }}
        />
        <Button
          icon="pi pi-times"
          className="p-button-danger mr-2 p-button-rounded"
          onClick={clearDateFilter}
          tooltip="Clear Filter"
          tooltipOptions={{ position: "bottom" }}
        />
      </div>

      <div className="mt-3 md:mt-0 flex justify-content-end">
        <Button
          icon="pi pi-plus"
          className="mr-2 p-button-rounded"
          onClick={openNew}
          tooltip="New"
          tooltipOptions={{ position: "bottom" }}
        />
        <Button
          icon="pi pi-trash"
          className="p-button-danger mr-2 p-button-rounded"
          onClick={confirmDeleteSelected}
          disabled={!selectedProducts || !selectedProducts.length}
          tooltip="Delete"
          tooltipOptions={{ position: "bottom" }}
        />
        <Button
          icon="pi pi-upload"
          className="p-button-help p-button-rounded"
          onClick={exportCSV}
          tooltip="Export"
          tooltipOptions={{ position: "bottom" }}
        />
      </div>
    </div>
  );
  const productDialogFooter = (
    <React.Fragment>
      <Button
        label="Cancel"
        icon="pi pi-times"
        className="p-button-text"
        onClick={hideDialog}
      />
      <Button label="Save" icon="pi pi-check" onClick={saveProduct} />
    </React.Fragment>
  );

  const deleteProductDialogFooter = (
    <React.Fragment>
      <Button
        label="No"
        icon="pi pi-times"
        className="p-button-text"
        onClick={hideDeleteProductDialog}
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        className="p-button-text"
        onClick={deleteProduct}
      />
    </React.Fragment>
  );

  const deleteProductsDialogFooter = (
    <React.Fragment>
      <Button
        label="No"
        icon="pi pi-times"
        className="p-button-text"
        onClick={hideDeleteProductsDialog}
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        className="p-button-text"
        onClick={deleteSelectedProducts}
      />
    </React.Fragment>
  );

  const getTagSeveritySale = (rowData) => {
    return rowData.sale === rowData.saleActual ? "success" : "danger";
  };

  const getTagSeverityBar = (rowData) => {
    return rowData.bar === rowData.barActual ? "success" : "danger";
  };

  const getTagSeverityCard = (rowData) => {
    return rowData.card === rowData.cardActual ? "success" : "danger";
  };

  const getTagSeverityBt = (rowData) => {
    return rowData.bankTransfer === rowData.bankTransferActual
      ? "success"
      : "danger";
  };

  const getTagSeverityPaypal = (rowData) => {
    return rowData.paypal === rowData.paypalActual ? "success" : "danger";
  };

  return (
    <div>
      <div className="card">
        {!isUserGroupMember && (
          <Accordion>
            <AccordionTab header="General - Calculations Actual">
              <div className="grid">
                {!isUserGroupMember && (
                  <>
                    <TotalCard
                      name="Total Sale"
                      totalAmount={totalSaleAmount}
                    ></TotalCard>
                    <TotalCard
                      name="Bar"
                      totalAmount={totalCashAmount}
                    ></TotalCard>
                    <TotalCard
                      name="Card"
                      totalAmount={totalCardAmount}
                    ></TotalCard>
                    <TotalCard
                      name="Bank Transfer"
                      totalAmount={totalBtAmount}
                    ></TotalCard>
                    <TotalCard
                      name="Paypal"
                      totalAmount={totalPaypalAmount}
                    ></TotalCard>
                    <TotalCard
                      name="Out"
                      totalAmount={totalOutAmount}
                    ></TotalCard>
                  </>
                )}
              </div>
            </AccordionTab>
          </Accordion>
        )}
      </div>
      <div className="card">
        {!isUserGroupMember && (
          <Accordion>
            <AccordionTab header="General - Calculations Original">
              <div className="grid">
                {!isUserGroupMember && (
                  <>
                    <TotalCard
                      name="Total Sale"
                      totalAmount={totalSaleAmountO}
                    ></TotalCard>
                    <TotalCard
                      name="Bar"
                      totalAmount={totalCashAmountO}
                    ></TotalCard>
                    <TotalCard
                      name="Card"
                      totalAmount={totalCardAmountO}
                    ></TotalCard>
                    <TotalCard
                      name="Bank Transfer"
                      totalAmount={totalBtAmountO}
                    ></TotalCard>
                    <TotalCard
                      name="Paypal"
                      totalAmount={totalPaypalAmountO}
                    ></TotalCard>
                  </>
                )}
              </div>
            </AccordionTab>
          </Accordion>
        )}
      </div>

      <div className="card">
        {!isUserGroupMember && (
          <Accordion>
            <AccordionTab header="General - Calculations Diff Original - Actual">
              <div className="grid">
                {!isUserGroupMember && (
                  <>
                    <TotalCard
                      name="Total Sale"
                      totalAmount={totalSaleAmountDiff}
                    ></TotalCard>
                    <TotalCard
                      name="Bar"
                      totalAmount={totalCashAmountDiff}
                    ></TotalCard>
                    <TotalCard
                      name="Card"
                      totalAmount={totalCardAmountDiff}
                    ></TotalCard>
                    <TotalCard
                      name="Bank Transfer"
                      totalAmount={totalBtAmountDiff}
                    ></TotalCard>
                    <TotalCard
                      name="Paypal"
                      totalAmount={totalPaypalAmountDiff}
                    ></TotalCard>
                  </>
                )}
              </div>
            </AccordionTab>
          </Accordion>
        )}
      </div>

      <div className="datatable-crud-demo surface-card p-4 border-round shadow-2">
        <Toast ref={toast} />

        <div className="text-3xl text-800 font-bold mb-4">
          ZAbschlag Management Portal
        </div>

        <DataTable
          ref={dt}
          value={filteredData}
          selection={selectedProducts}
          onSelectionChange={(e) => setSelectedProducts(e.value)}
          dataKey="id"
          paginator
          rows={10}
          rowsPerPageOptions={[5, 10, 25]}
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
          globalFilter={globalFilter}
          header={header}
          responsiveLayout="scroll"
        >
          <Column
            selectionMode="multiple"
            headerStyle={{ width: "3rem" }}
            exportable={false}
          ></Column>
          <Column
            field="date"
            header="Date"
            sortable
            style={{ minWidth: "12rem" }}
          ></Column>
          <Column
            field="sale"
            header="Sale"
            body={(rowData) => formatCurrency(rowData.sale)}
            sortable
            style={{ minWidth: "10rem" }}
          ></Column>
          <Column
            field="saleActual"
            header="Sale Actual"
            body={(rowData) => (
              <Tag
                value={formatCurrency(rowData.saleActual)}
                severity={getTagSeveritySale(rowData)}
              />
            )}
            sortable
            style={{ minWidth: "10rem" }}
          ></Column>
          <Column
            field="bar"
            header="Bar"
            body={(rowData) => formatCurrency(rowData.bar)}
            sortable
            style={{ minWidth: "10rem" }}
          ></Column>
          <Column
            field="barActual"
            header="Bar Actual"
            body={(rowData) => (
              <Tag
                value={formatCurrency(rowData.barActual)}
                severity={getTagSeverityBar(rowData)}
              />
            )}
            sortable
            style={{ minWidth: "10rem" }}
          ></Column>
          {(rowData) => console.log(rowData)}

          <Column
            field="card"
            header="Card"
            body={(rowData) => formatCurrency(rowData.card)}
            sortable
            style={{ minWidth: "10rem" }}
          ></Column>
          <Column
            field="cardActual"
            header="Card Actual"
            body={(rowData) => (
              <Tag
                value={formatCurrency(rowData.cardActual)}
                severity={getTagSeverityCard(rowData)}
              />
            )}
            sortable
            style={{ minWidth: "10rem" }}
          ></Column>
          <Column
            field="bankTransfer"
            header="Bank Transfer"
            body={(rowData) => formatCurrency(rowData.bankTransfer)}
            sortable
            style={{ minWidth: "10rem" }}
          ></Column>
          <Column
            field="bankTransferActual"
            header="Bank Transfer Actual"
            body={(rowData) => (
              <Tag
                value={formatCurrency(rowData.bankTransferActual)}
                severity={getTagSeverityBt(rowData)}
              />
            )}
            sortable
            style={{ minWidth: "10rem" }}
          ></Column>
          <Column
            field="paypal"
            header="Paypal"
            body={(rowData) => formatCurrency(rowData.paypal)}
            sortable
            style={{ minWidth: "10rem" }}
          ></Column>
          <Column
            field="paypalActual"
            header="Paypal Actual"
            body={(rowData) => (
              <Tag
                value={formatCurrency(rowData.paypalActual)}
                severity={getTagSeverityPaypal(rowData)}
              />
            )}
            sortable
            style={{ minWidth: "10rem" }}
          ></Column>
          <Column
            field="out"
            header="Out"
            body={(rowData) => formatCurrency(rowData.out)}
            sortable
            style={{ minWidth: "10rem" }}
          ></Column>
          <Column
            field="description"
            header="Description"
            sortable
            style={{ minWidth: "10rem" }}
          ></Column>
          <Column
            field="status"
            header="Status"
            body={statusDisplay}
            sortable
            style={{ minWidth: "10rem" }}
          ></Column>
          <Column
            field="file"
            header="ZAschlag File"
            body={uploadedFileDisplay}
            sortable
            style={{ minWidth: "10rem" }}
          ></Column>

          <Column
            body={actionBodyTemplate}
            exportable={false}
            style={{ minWidth: "8rem" }}
          ></Column>
        </DataTable>

        <Dialog
          visible={productDialog}
          breakpoints={{ "960px": "75vw", "640px": "100vw" }}
          style={{ width: "40vw" }}
          header="ZAbschalg Details"
          modal
          className="p-fluid"
          footer={productDialogFooter}
          onHide={hideDialog}
        >
          {product.image && (
            <img
              src={`demo/images/product/${product.image}`}
              onError={(e) =>
                (e.target.src =
                  "https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png")
              }
              alt={product.image}
              className="block mt-0 mx-auto mb-5 w-20rem shadow-2"
            />
          )}
          <div className="field">
            <div className="formgrid grid">
              <div className="field col-6">
                <label htmlFor="date">Date</label>
                <Calendar
                  inputId="date"
                  value={product.date}
                  onChange={(e) => onDateChange(e, "date")}
                  showIcon
                  required
                  autoFocus
                  className={classNames({
                    "p-invalid": submitted && !product.date,
                  })}
                  disabledDates={disabledDates}
                />
                {submitted && !product.date && (
                  <small className="p-error">Date is required.</small>
                )}
              </div>
              <div className="field col-6">
                <label htmlFor="sale">sale</label>
                <InputNumber
                  id="sale"
                  value={product.sale}
                  onValueChange={(e) => onInputNumberChange(e, "sale")}
                  mode="currency"
                  currency="EUR"
                  locale="en-US"
                  required
                  autoFocus
                  className={classNames({
                    "p-invalid": submitted && !product.sale,
                  })}
                />
                {submitted && !product.sale && (
                  <small className="p-error">sale is required.</small>
                )}
              </div>
            </div>
          </div>

          <div className="field">
            <div className="formgrid grid">
              <div className="field col-3">
                <label htmlFor="bar">Bar</label>
                <InputNumber
                  id="bar"
                  value={product.bar}
                  onValueChange={(e) => onInputNumberChange(e, "bar")}
                  mode="currency"
                  currency="EUR"
                  locale="en-US"
                />
              </div>
              <div className="field col-3">
                <label htmlFor="barActual">Bar Actual</label>
                <InputNumber
                  id="barActual"
                  value={
                    (product.barActual = isEditing
                      ? product.barActual
                      : product.isFieldEdited?.barActual
                      ? product.barActual
                      : product.bar)
                  }
                  onValueChange={(e) => onInputNumberAllChange(e, "barActual")}
                  mode="currency"
                  currency="EUR"
                  locale="en-US"
                />
              </div>
              <div className="field col-3">
                <label htmlFor="card">Card</label>
                <InputNumber
                  id="card"
                  value={product.card}
                  onValueChange={(e) => onInputNumberChange(e, "card")}
                  mode="currency"
                  currency="EUR"
                  locale="en-US"
                />
              </div>
              <div className="field col-3">
                <label htmlFor="cardActual">Card Actual</label>
                <InputNumber
                  id="cardActual"
                  value={
                    (product.cardActual = isEditing
                      ? product.cardActual
                      : product.isFieldEdited?.cardActual
                      ? product.cardActual
                      : product.card)
                  }
                  onValueChange={(e) => onInputNumberAllChange(e, "cardActual")}
                  mode="currency"
                  currency="EUR"
                  locale="en-US"
                />
              </div>
            </div>
          </div>

          <div className="field">
            <div className="formgrid grid">
              <div className="field col-3">
                <label htmlFor="bankTransfer">Bank Transfer</label>
                <InputNumber
                  id="bankTransfer"
                  value={product.bankTransfer}
                  onValueChange={(e) => onInputNumberChange(e, "bankTransfer")}
                  mode="currency"
                  currency="EUR"
                  locale="en-US"
                />
              </div>
              <div className="field col-3">
                <label htmlFor="bankTransferActual">Bank Transfer Actual</label>
                <InputNumber
                  id="bankTransferActual"
                  value={
                    (product.bankTransferActual = isEditing
                      ? product.bankTransferActual
                      : product.isFieldEdited?.bankTransferActual
                      ? product.bankTransferActual
                      : product.bankTransfer)
                  }
                  onValueChange={(e) =>
                    onInputNumberAllChange(e, "bankTransferActual")
                  }
                  mode="currency"
                  currency="EUR"
                  locale="en-US"
                />
              </div>
              <div className="field col-3">
                <label htmlFor="paypal">Paypal</label>
                <InputNumber
                  id="paypal"
                  value={product.paypal}
                  onValueChange={(e) => onInputNumberChange(e, "paypal")}
                  mode="currency"
                  currency="EUR"
                  locale="en-US"
                />
              </div>
              <div className="field col-3">
                <label htmlFor="paypalActual">Paypal Actual</label>
                <InputNumber
                  id="paypalActual"
                  value={
                    (product.paypalActual = isEditing
                      ? product.paypalActual
                      : product.isFieldEdited?.paypalActual
                      ? product.paypalActual
                      : product.paypal)
                  }
                  onValueChange={(e) =>
                    onInputNumberAllChange(e, "paypalActual")
                  }
                  mode="currency"
                  currency="EUR"
                  locale="en-US"
                />
              </div>
            </div>
          </div>

          <div className="field">
            <div className="formgrid grid">
              <div className="field col-6">
                <label htmlFor="out">Out</label>
                <InputNumber
                  id="description"
                  value={product.out}
                  onValueChange={(e) => onInputNumberChange(e, "out")}
                  mode="currency"
                  currency="EUR"
                  locale="en-US"
                />
              </div>
            </div>
          </div>

          <div className="field">
            <label htmlFor="description">Description</label>
            <InputTextarea
              id="description"
              value={product.description}
              onChange={(e) => onInputChange(e, "description")}
              required
              rows={3}
              cols={20}
            />
          </div>

          <div className="field">
            <label htmlFor="uploadFiles">Upload Files</label>
            <FileUpload
              name="Abschlag"
              customUpload={true}
              uploadHandler={invoiceUploadHandler}
              mode="basic"
              chooseLabel="Upload Abschlag"
            />
          </div>
          <div className="field">
            <div className="formgrid grid">
              <div className="field col-6">
                <label htmlFor="status">Status</label>
                <Dropdown
                  id="status"
                  value={product.status}
                  onChange={onStatusChange}
                  options={statusOptions}
                  optionLabel="name"
                  optionValue="value"
                  placeholder="Select a Status"
                  className="w-full md:w-14rem"
                />
              </div>
              <div className="field col-5">
                <label htmlFor="saleActual">sale Actual Confirmation</label>
                <InputNumber
                  id="saleActual"
                  value={calculateSaleActual()} // Ensure calculated value is rounded and formatted
                  onValueChange={(e) => onInputNumberChange(e, "saleActual")}
                  mode="currency"
                  currency="EUR"
                  locale="en-US"
                />
              </div>
              <div className="field col-1">
                {(
                  product.barActual +
                  product.cardActual +
                  product.bankTransferActual +
                  product.paypalActual
                ).toFixed(2) === product.sale.toFixed(2) ? (
                  <Avatar
                    icon="pi pi-check"
                    className="mr-2 mt-4"
                    size="medium"
                    style={{ backgroundColor: "#23C552", color: "#ffffff" }}
                    shape="circle"
                  />
                ) : (
                  <Avatar
                    icon="pi pi-times"
                    className="mr-2 mt-4"
                    size="medium"
                    style={{ backgroundColor: "#F84F31", color: "#ffffff" }}
                    shape="circle"
                  />
                )}
              </div>
            </div>
          </div>
        </Dialog>

        <Dialog
          visible={deleteProductDialog}
          style={{ width: "450px" }}
          header="Confirm"
          modal
          footer={deleteProductDialogFooter}
          onHide={hideDeleteProductDialog}
        >
          <div className="flex align-items-center justify-content-center">
            <i
              className="pi pi-exclamation-triangle mr-3"
              style={{ fontSize: "2rem" }}
            />
            {product && (
              <span>
                Are you sure you want to delete <b>{product.date}</b>?
              </span>
            )}
          </div>
        </Dialog>

        <Dialog
          visible={deleteProductsDialog}
          style={{ width: "450px" }}
          header="Confirm"
          modal
          footer={deleteProductsDialogFooter}
          onHide={hideDeleteProductsDialog}
        >
          <div className="flex align-items-center justify-content-center">
            <i
              className="pi pi-exclamation-triangle mr-3"
              style={{ fontSize: "2rem" }}
            />
            {product && (
              <span>Are you sure you want to delete the selected Entries?</span>
            )}
          </div>
        </Dialog>
      </div>
    </div>
  );
}

export default ZAbschlagManagementPortal;
