import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import axios, { AxiosResponse } from 'axios';
import keycloak from "../../keycloak";

const API_URL = 'https://app-membership.onrender.com';


export const getData = async () => {
  return axios
    .get(`${API_URL}/getsale`, {
      headers: {
        Authorization: `Bearer ${keycloak.token}`,
      },
    })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};

export const useGetData = () => {
  const queryFn = async (): Promise<any> => {
    const tableData = await getData();
    return tableData;
  };
  return useQuery(['get-sale-data'],
    queryFn,
  );

};

export const createNew = async (requestBody: any) => {

  return axios
    .post(`${API_URL}/newsale`, requestBody, {
      headers: {
        Authorization: `Bearer ${keycloak.token}`,

      },
    })
    .then(({ data: newsubs }) => {
      return newsubs
    })
    .catch((error) => {
      throw error
    })
}

export const useCreateNew = () => {
  const queryClient = useQueryClient();
  return useMutation(createNew, {
    onSuccess: () => {
      queryClient.invalidateQueries(['get-sale-data']);
    },
  })
}


export const update = async (id: any, requestData: any) => {
  try {
    const response = await axios.put(`${API_URL}/updatesale?id=${id}`, requestData, {
      headers: {
        Authorization: `Bearer ${keycloak.token}`,
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const useUpdate = () => {
  const queryClient = useQueryClient();
  return useMutation<AxiosResponse<any, any>, Error, { id: any, data: any }>(
    ({ id, data }) => update(id, data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['get-sale-data']);
      },
    }
  );
}

export const deleteEntry = async (id: any) => {
  try {
    const response = await axios.delete(`${API_URL}/deletesale?id=${id}`, {
      headers: {
        Authorization: `Bearer ${keycloak.token}`,
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};


export const useDeleteEntry = () => {
  const queryClient = useQueryClient();
  return useMutation(deleteEntry, {
    onSuccess: () => {
      queryClient.invalidateQueries(['get-sale-data']);
    },
  })
}
