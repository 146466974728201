import React, { useState, useRef } from "react";
import { classNames } from "primereact/utils";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import { Calendar } from "primereact/calendar";
import { Tag } from "primereact/tag";
import moment from "moment";
import {
  useGetMembersData,
  useCreateMember,
  useDeleteMember,
  useUpdateMember,
  useSendEmail,
} from "./services/DataServices";

function MembershipPortal() {
  let emptyItem = {
    name: "",
    street: "",
    plz: "",
    city: "",
    country: "",
    email: "",
    mobile: "",
    status: 0,
  };

  const getMembersData = useGetMembersData();
  const createMember = useCreateMember();
  const deleteMember = useDeleteMember();
  const updateMember = useUpdateMember();
  const sendEmailEndpoint = useSendEmail();

  const [products, setProducts] = useState(null);
  const [productDialog, setProductDialog] = useState(false);
  const [deleteProductDialog, setDeleteProductDialog] = useState(false);
  const [deleteProductsDialog, setDeleteProductsDialog] = useState(false);
  const [product, setProduct] = useState(emptyItem);
  const [selectedProducts, setSelectedProducts] = useState(null);
  const [submitted, setSubmitted] = useState(false);
  const [globalFilter, setGlobalFilter] = useState(null);
  const toast = useRef(null);
  const dt = useRef(null);

  const openNew = () => {
    setProduct(emptyItem);
    setSubmitted(false);
    setProductDialog(true);
  };

  const hideDialog = () => {
    setSubmitted(false);
    setProductDialog(false);
  };

  const hideDeleteProductDialog = () => {
    setDeleteProductDialog(false);
  };

  const hideDeleteProductsDialog = () => {
    setDeleteProductsDialog(false);
  };

  const saveProduct = () => {
    setSubmitted(true);
    console.log(product);
    if (
      product.name.trim() &&
      product.street !== "" &&
      product.plz !== "" &&
      product.city !== "" &&
      product.country !== "" &&
      product.email !== ""
    ) {
      // let _products = [...products];
      let _product = { ...product };
      if (product.id) {
        if (typeof _product.startDate !== "string") {
          _product.startDate = moment(_product.startDate).format("YYYY-MM-DD");
        }
        updateMember.mutate(
          {
            id: _product.id,
            data: _product,
          },
          {
            onSuccess: (response) => {
              toast.current.show({
                severity: "success",
                summary: "Successful",
                detail: response.message,
                life: 3000,
              });
            },
            onError: (response) => {
              console.log(response);
              toast.current.show({
                severity: "error",
                summary: "Error",
                detail: response.response.data.detail,
                life: 3000,
              });
            },
          }
        );
      } else {
        if (_product.startDate !== "") {
          _product.startDate = moment(_product.startDate).format("YYYY-MM-DD");
        }
        createMember.mutate(_product, {
          onSuccess: (response) => {
            toast.current.show({
              severity: "success",
              summary: "Successful",
              detail: response.message,
              life: 3000,
            });
          },
          onError: (response) => {
            console.log(response);
            toast.current.show({
              severity: "error",
              summary: "Error",
              detail: response.response.data.detail,
              life: 3000,
            });
          },
        });
      }
      // setProducts(_products);
      setProductDialog(false);
      setProduct(emptyItem);
    }
  };

  const editProduct = (product) => {
    if (product.status === "Active") {
      product.status = 0;
    }
    if (product.status === "Expired") {
      product.status = 1;
    }
    setProduct({ ...product });
    setProductDialog(true);
  };

  const sendEmail = (product) => {
    setProduct({ ...product });
    sendEmailEndpoint.mutate(product, {
      onSuccess: (response) => {
        toast.current.show({
          severity: "success",
          summary: "Successful",
          detail: response.message,
          life: 3000,
        });
      },
      onError: (response) => {
        console.log(response);
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: response.response.data.detail,
          life: 3000,
        });
      },
    });
    console.log(product);
  };

  const confirmDeleteProduct = (product) => {
    setProduct(product);
    setDeleteProductDialog(true);
  };

  const deleteProduct = () => {
    // let _products = products.filter(val => val.id !== product.id);
    deleteMember.mutate(product.id, {
      onSuccess: (response) => {
        toast.current.show({
          severity: "success",
          summary: "Successful",
          detail: response.message,
          life: 3000,
        });
      },
      onError: (response) => {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: response.message,
          life: 3000,
        });
      },
    });
    setDeleteProductDialog(false);
  };

  const exportCSV = () => {
    dt.current.exportCSV();
  };

  const confirmDeleteSelected = () => {
    setDeleteProductsDialog(true);
  };

  const deleteSelectedProducts = () => {
    let _products = products.filter((val) => !selectedProducts.includes(val));
    setProducts(_products);
    setDeleteProductsDialog(false);
    setSelectedProducts(null);
    toast.current.show({
      severity: "success",
      summary: "Successful",
      detail: "Products Deleted",
      life: 3000,
    });
  };

  const onInputChange = (e, name) => {
    const val = (e.target && e.target.value) || "";
    let _product = { ...product };
    _product[`${name}`] = val;
    setProduct(_product);
  };

  const onDateChange = (e, name) => {
    let _product = { ...product };
    _product[`${name}`] = e.value;
    setProduct(_product);
  };

  const statusDisplay = (rowData) => {
    if (rowData.status === 0) {
      rowData.status = "Active";
    }
    if (rowData.status === 1) {
      rowData.status = "Expired";
    }
    return <Tag value={rowData.status} severity={getSeverity(rowData)}></Tag>;
  };

  const getSeverity = (rowData) => {
    switch (rowData.status) {
      case "Closed":
        return "Active";

      case "Expired":
        return "danger";

      default:
        return null;
    }
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <Button
          icon="pi pi-envelope"
          className="p-button-rounded p-button-info mr-1"
          onClick={() => sendEmail(rowData)}
        />
        <Button
          icon="pi pi-pencil"
          className="p-button-rounded p-button-success mr-1"
          onClick={() => editProduct(rowData)}
        />
        <Button
          icon="pi pi-trash"
          className="p-button-rounded p-button-danger"
          onClick={() => confirmDeleteProduct(rowData)}
        />
      </React.Fragment>
    );
  };

  const header = (
    <div className="flex flex-column md:flex-row md:align-items-center justify-content-between">
      <span className="p-input-icon-left w-full md:w-auto">
        <i className="pi pi-search" />
        <InputText
          type="search"
          onInput={(e) => setGlobalFilter(e.target.value)}
          placeholder="Search..."
          className="w-full lg:w-auto"
        />
      </span>
      <div className="mt-3 md:mt-0 flex justify-content-end">
        <Button
          icon="pi pi-plus"
          className="mr-2 p-button-rounded"
          onClick={openNew}
          tooltip="New"
          tooltipOptions={{ position: "bottom" }}
        />
        <Button
          icon="pi pi-trash"
          className="p-button-danger mr-2 p-button-rounded"
          onClick={confirmDeleteSelected}
          disabled={!selectedProducts || !selectedProducts.length}
          tooltip="Delete"
          tooltipOptions={{ position: "bottom" }}
        />
        <Button
          icon="pi pi-upload"
          className="p-button-help p-button-rounded"
          onClick={exportCSV}
          tooltip="Export"
          tooltipOptions={{ position: "bottom" }}
        />
      </div>
    </div>
  );
  const productDialogFooter = (
    <React.Fragment>
      <Button
        label="Cancel"
        icon="pi pi-times"
        className="p-button-text"
        onClick={hideDialog}
      />
      <Button label="Save" icon="pi pi-check" onClick={saveProduct} />
    </React.Fragment>
  );

  const deleteProductDialogFooter = (
    <React.Fragment>
      <Button
        label="No"
        icon="pi pi-times"
        className="p-button-text"
        onClick={hideDeleteProductDialog}
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        className="p-button-text"
        onClick={deleteProduct}
      />
    </React.Fragment>
  );

  const deleteProductsDialogFooter = (
    <React.Fragment>
      <Button
        label="No"
        icon="pi pi-times"
        className="p-button-text"
        onClick={hideDeleteProductsDialog}
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        className="p-button-text"
        onClick={deleteSelectedProducts}
      />
    </React.Fragment>
  );

  return (
    <div>
      <div className="datatable-crud-demo surface-card p-4 border-round shadow-2">
        <Toast ref={toast} />

        <div className="text-3xl text-800 font-bold mb-4">
          Membership Management Portal
        </div>

        <DataTable
          ref={dt}
          value={getMembersData.data?.result}
          selection={selectedProducts}
          onSelectionChange={(e) => setSelectedProducts(e.value)}
          dataKey="id"
          paginator={!getMembersData.isLoading}
          rows={10}
          rowsPerPageOptions={[5, 10, 25]}
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
          globalFilter={globalFilter}
          header={header}
          responsiveLayout="scroll"
        >
          <Column
            selectionMode="multiple"
            headerStyle={{ width: "3rem" }}
            exportable={false}
          ></Column>
          <Column
            field="barcode"
            header="Barcode"
            sortable
            style={{ minWidth: "12rem" }}
          ></Column>
          <Column
            field="name"
            header="Name"
            sortable
            style={{ minWidth: "12rem" }}
          ></Column>
          <Column
            field="street"
            header="Street"
            sortable
            style={{ minWidth: "16rem" }}
          ></Column>
          <Column
            field="plz"
            header="Post Code"
            sortable
            style={{ minWidth: "12rem" }}
          ></Column>
          <Column
            field="city"
            header="City"
            sortable
            style={{ minWidth: "8rem" }}
          ></Column>
          <Column
            field="country"
            header="Country"
            sortable
            style={{ minWidth: "10rem" }}
          ></Column>
          <Column
            field="email"
            header="Email"
            sortable
            style={{ minWidth: "12rem" }}
          ></Column>
          <Column
            field="mobile"
            header="Mobile"
            sortable
            style={{ minWidth: "12rem" }}
          ></Column>
          <Column
            field="discount"
            header="Discount"
            sortable
            style={{ minWidth: "12rem" }}
          ></Column>
          <Column
            field="visitCount"
            header="Visit Count"
            sortable
            style={{ minWidth: "12rem" }}
          ></Column>
          <Column
            field="visitDate"
            header="Visit Date"
            sortable
            style={{ minWidth: "12rem" }}
          ></Column>
          <Column
            field="status"
            header="Status"
            body={statusDisplay}
            sortable
            style={{ minWidth: "8rem" }}
          ></Column>
          <Column
            body={actionBodyTemplate}
            exportable={false}
            style={{ minWidth: "12rem" }}
          ></Column>
        </DataTable>

        <Dialog
          visible={productDialog}
          breakpoints={{ "960px": "75vw", "640px": "100vw" }}
          style={{ width: "40vw" }}
          header="Member Details"
          modal
          className="p-fluid"
          footer={productDialogFooter}
          onHide={hideDialog}
        >
          {product.image && (
            <img
              src={`demo/images/product/${product.image}`}
              onError={(e) =>
                (e.target.src =
                  "https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png")
              }
              alt={product.image}
              className="block mt-0 mx-auto mb-5 w-20rem shadow-2"
            />
          )}
          <div className="field">
            <div className="formgrid grid">
              <div className="field col-12">
                <label htmlFor="name">Name</label>
                <InputText
                  id="name"
                  value={product.name}
                  onChange={(e) => onInputChange(e, "name")}
                  required
                  autoFocus
                  className={classNames({
                    "p-invalid": submitted && !product.name,
                  })}
                />
                {submitted && !product.name && (
                  <small className="p-error">Name is required.</small>
                )}
              </div>
            </div>
          </div>

          <div className="field">
            <div className="formgrid grid">
              <div className="field col-12">
                <label htmlFor="street">Street</label>
                <InputText
                  id="name"
                  value={product.street}
                  onChange={(e) => onInputChange(e, "street")}
                  required
                  autoFocus
                  className={classNames({
                    "p-invalid": submitted && !product.street,
                  })}
                />
                {submitted && !product.street && (
                  <small className="p-error">Street is required.</small>
                )}
              </div>
            </div>
          </div>

          <div className="field">
            <div className="formgrid grid">
              <div className="field col-6">
                <label htmlFor="plz">Plz</label>
                <InputText
                  id="plz"
                  value={product.plz}
                  onChange={(e) => onInputChange(e, "plz")}
                  required
                  autoFocus
                  className={classNames({
                    "p-invalid": submitted && !product.plz,
                  })}
                />
                {submitted && !product.plz && (
                  <small className="p-error">Post code is required.</small>
                )}
              </div>
              <div className="field col-6">
                <label htmlFor="city">City</label>
                <InputText
                  id="city"
                  value={product.city}
                  onChange={(e) => onInputChange(e, "city")}
                  required
                  autoFocus
                  className={classNames({
                    "p-invalid": submitted && !product.city,
                  })}
                />
                {submitted && !product.city && (
                  <small className="p-error">City is required.</small>
                )}
              </div>
            </div>
          </div>

          <div className="formgrid grid">
            <div className="field col">
              <label htmlFor="country">Country</label>
              <InputText
                id="country"
                value={product.country}
                onChange={(e) => onInputChange(e, "country")}
                required
                autoFocus
                className={classNames({
                  "p-invalid": submitted && !product.country,
                })}
              />
              {submitted && !product.country && (
                <small className="p-error">Country is required.</small>
              )}
            </div>
            <div className="field col">
              <label htmlFor="mobile">Mobile</label>
              <InputText
                id="mobile"
                value={product.mobile}
                onChange={(e) => onInputChange(e, "mobile")}
                required
                autoFocus
                className={classNames({
                  "p-invalid": submitted && !product.mobile,
                })}
              />
            </div>
          </div>
          <div className="field">
            <label htmlFor="email">Email</label>
            <InputText
              id="email"
              value={product.email}
              onChange={(e) => onInputChange(e, "email")}
              required
              autoFocus
              className={classNames({
                "p-invalid": submitted && !product.email,
              })}
            />
            {submitted && !product.email && (
              <small className="p-error">Email is required.</small>
            )}
          </div>
          <div className="field">
            <div className="form-grid grid">
              <div className="field col-6">
                <label htmlFor="date">Start Date</label>
                <Calendar
                  inputId="startDate"
                  value={product.startDate}
                  onChange={(e) => onDateChange(e, "startDate")}
                  showIcon
                  required
                  autoFocus
                  className={classNames({
                    "p-invalid": submitted && !product.startDate,
                  })}
                />
              </div>
            </div>
          </div>
        </Dialog>

        <Dialog
          visible={deleteProductDialog}
          style={{ width: "450px" }}
          header="Confirm"
          modal
          footer={deleteProductDialogFooter}
          onHide={hideDeleteProductDialog}
        >
          <div className="flex align-items-center justify-content-center">
            <i
              className="pi pi-exclamation-triangle mr-3"
              style={{ fontSize: "2rem" }}
            />
            {product && (
              <span>
                Are you sure you want to delete <b>{product.name}</b>?
              </span>
            )}
          </div>
        </Dialog>

        <Dialog
          visible={deleteProductsDialog}
          style={{ width: "450px" }}
          header="Confirm"
          modal
          footer={deleteProductsDialogFooter}
          onHide={hideDeleteProductsDialog}
        >
          <div className="flex align-items-center justify-content-center">
            <i
              className="pi pi-exclamation-triangle mr-3"
              style={{ fontSize: "2rem" }}
            />
            {product && (
              <span>Are you sure you want to delete the selected Entries?</span>
            )}
          </div>
        </Dialog>
      </div>
    </div>
  );
}

export default MembershipPortal;
