import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import axios, { AxiosResponse } from 'axios';
import keycloak from "../../keycloak";

const API_URL = 'https://app-membership.onrender.com';

export const getTableData = async (table_name: string) => {
    return axios
      .get(`${API_URL}/get_data/?table_name=${table_name}`, {
        headers: {
          Authorization: `Bearer ${keycloak.token}`,
        },
      })
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  };
  
  export const useTableData = (table_name:string) => {
    const queryFn = async (): Promise<any> => {
      const tableData = await getTableData(table_name);
      return tableData;
    };
    return useQuery(['get-table-data'],
      queryFn, 
    );
  
  };

  export const updateRowData = async (table_name: string, data: any) => {
    try {
      const response = await axios.put(`${API_URL}/update_data/?table_name=${table_name}`, data, {
        headers: {
          Authorization: `Bearer ${keycloak.token}`,
        },
      });
  
      return response;
    } catch (error) {
      throw error;
    }
  };
  
  
  export const useUpdateRowData = () => {
    const queryClient = useQueryClient();
    return useMutation<AxiosResponse<any, any>, Error, { table_name: string, data: any }>(
      ({ table_name, data }) => updateRowData(table_name, data),
      {
        onSuccess: () => {
          queryClient.invalidateQueries(['get-table-data']);
        },
      }
    );
  };

  export const deleteRow = async (table_name: string, data: any) => {
    try {
      const response = await axios.delete(`${API_URL}/delete_data/?table_name=${table_name}`, {
        headers: {
          Authorization: `Bearer ${keycloak.token}`,
        },
        data: {
          id: data.id, // Assuming you want to delete by ID
        },
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  export const useDeleteRow = () => {
    const queryClient = useQueryClient();
    return useMutation<AxiosResponse<any, any>, Error, { table_name: string, data: any }>(
      ({ table_name, data }) => deleteRow(table_name, data),
      {
        onSuccess: () => {
          queryClient.invalidateQueries(['get-table-data']);
        },
      }
    );
  }


  

