import React from 'react';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { confirmPopup, ConfirmPopup } from 'primereact/confirmpopup';
import { ProgressSpinner } from 'primereact/progressspinner';
import {
    fetchExpiringProducts,
    approveProduct,
} from "./services/DataServices";


function ExpiringProducts() {
    const queryClient = useQueryClient();
    const { data, error, isLoading } = useQuery(['expiring-products'], fetchExpiringProducts);
    const mutation = useMutation(
        ({ productId, status }) => approveProduct(productId, status),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(['expiring-products']);
                toast.current.show({ severity: 'success', summary: 'Success', detail: 'Product approved successfully', life: 3000 });
            },
            onError: () => {
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Failed to approve product', life: 3000 });
            }
        }
    );

    const toast = React.useRef(null);

    const handleApprove = (productId, status) => {
        mutation.mutate({ productId, status });
    };

    const confirmApproveCorrect = (event, productId) => {
        confirmPopup({
            target: event.currentTarget,
            message: 'Are you sure you want to approve as CORRECT product?',
            icon: 'pi pi-exclamation-triangle',
            accept: () => handleApprove(productId, 'Correct')
        });
    };

    const confirmApproveNotCorrect = (event, productId) => {
        confirmPopup({
            target: event.currentTarget,
            message: 'Are you sure you want to approve as NOT CORRECT product?',
            icon: 'pi pi-exclamation-triangle',
            accept: () => handleApprove(productId, 'Not Correct')
        });
    };

    if (isLoading) {
        return <ProgressSpinner />;
    }

    if (error) {
        return <div>Error loading products</div>;
    }

    let formattedData;
    if (data) {
        //   // Convert the raw data into an array of objects
        formattedData = data?.map((row) => ({
            id: row[0],
            barcode: row[1],
            productName: row[2],
            stock: row[3],
            expiry_date: row[4],
            timestamp: row[5],
            distributor: row[6],
            actual_bestand: row[7],
            status: row[8],
            //date: new Date(row[5]).toLocaleDateString(),
        }));
    }

    // Define columns for the DataTable
    const columns = [
        { field: "id", header: "ID" },
        { field: "barcode", header: "Barcode" },
        { field: "productName", header: "Product Name" },
        { field: "stock", header: "Stock" },
        { field: "expiry_date", header: "Expiry Date" },
        { field: "timestamp", header: "Timestamp" },
        { field: "distributor", header: "Distributor" },
        { field: "actual_bestand", header: "Actual Stock" },
        { field: "status", header: "Status" },
    ];

    return (
        <div>
            <Toast ref={toast} />
            <h1>Expiring Products</h1>
            <ConfirmPopup />
            <DataTable
                value={formattedData}
                // selection={selectedProducts}
                // onSelectionChange={(e) => setSelectedProducts(e.value)}
                dataKey="id"
                paginator
                rows={10}
                rowsPerPageOptions={[5, 10, 25]}
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                // globalFilter={globalFilter}
                // header={header}
                responsiveLayout="scroll"
            >
                <Column
                    selectionMode="multiple"
                    headerStyle={{ width: "3rem" }}
                    exportable={false}
                ></Column>
                {columns.map((column) => (
                    <Column
                        key={column.field}
                        field={column.field}
                        header={column.header}
                        sortable
                        style={{ minWidth: "12rem" }}
                    />
                ))}
                <Column
                    body={(rowData) => (
                        <div>
                            <Button
                                label="Correct"
                                icon="pi pi-check"
                                onClick={(e) => confirmApproveCorrect(e, rowData.id)}
                                className="p-button-success p-mr-2" // Green color
                            />
                            <Button
                                label="Not Correct"
                                icon="pi pi-times"
                                onClick={(e) => confirmApproveNotCorrect(e, rowData.id)}
                                className="p-button-danger" // Red color
                            />
                        </div>
                    )}
                    style={{ minWidth: "8rem" }}
                    header="Actions"
                />
            </DataTable>
            {/*<DataTable value={data} paginator rows={10} className="p-datatable-gridlines">*/}
            {/*    <Column field="ID" header="ID" />*/}
            {/*    <Column field="Barcode" header="Barcode" />*/}
            {/*    <Column field="Product_Name" header="Product Name" />*/}
            {/*    <Column field="expiry_date" header="Expiry Date" />*/}
            {/*    <Column*/}
            {/*        body={(rowData) => (*/}
            {/*            <Button label="Approve" icon="pi pi-check" onClick={(e) => confirmApprove(e, rowData.id)} />*/}
            {/*        )}*/}
            {/*        header="Actions"*/}
            {/*    />*/}
            {/*</DataTable>*/}
        </div>
    );
}

export default ExpiringProducts;
