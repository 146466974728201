import React, { useState, useRef, useEffect } from 'react';
import { FileUpload } from 'primereact/fileupload';
import { useCreateFileUpload } from "./services/DataServices";
import { Toast } from "primereact/toast";

const FileUploader = () => {
  const toast = useRef(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploading, setUploading] = useState(false); // Track upload state
  const createFileUploadMutation = useCreateFileUpload();

  const onFileUpload = () => {
    if (!selectedFile || uploading) return; // Ensure a file is selected and not already uploading
    setUploading(true); // Set uploading state to true

    const requestBody = { file: selectedFile };
    createFileUploadMutation.mutate(requestBody, {
      onSuccess: (data) => {
        toast.current.show({
          severity: "success",
          summary: "Successful",
          detail: data.message,
          life: 3000,
        });
        setUploading(false); // Reset uploading state
      },
      onError: (error) => {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: error.response.data.detail,
          life: 3000,
        });
        setUploading(false); // Reset uploading state
      }
    });
  };

  // useEffect to ensure selectedFile state is updated before file upload
  useEffect(() => {
    if (selectedFile) {
      onFileUpload(); // Call onFileUpload when selectedFile changes
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFile]);

  return (
    <div className="file-uploader-container">
      <h2>Stock Update - File Uploader</h2>
      <Toast ref={toast} />
      <FileUpload
        chooseLabel="Choose"
        uploadLabel="Upload"
        cancelLabel="Cancel"
        name="stockfileupload"
        auto
        customUpload
        onSelect={(e) => setSelectedFile(e.files[0])}
        maxFileSize={4 * 1024 * 1024} // Set maximum file size to 4 MB
        accept=".csv" // Accept only .csv files
        disabled={uploading} // Disable file upload while uploading
      />
    </div>
  );
};

export default FileUploader;
