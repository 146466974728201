import React, { useState, useRef } from "react";
import { classNames } from "primereact/utils";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { InputNumber } from "primereact/inputnumber";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import moment from "moment";
import {
  useTableData,
  useUpdateRowData,
  useDeleteRow,
} from "./services/LagerBestandService";

function LagerBestandPortal() {
  let emptyItem = {
    id: null,
    barcode: "",
    productName: "",
    stock: 0,
    expiryDate: "",
    timestamp: "",
  };

  const tableData = useTableData("LagerBestand");
  const updateData = useUpdateRowData();
  const deleteData = useDeleteRow();
  console.log(tableData);
  const [products, setProducts] = useState([]);
  const [productDialog, setProductDialog] = useState(false);
  const [deleteProductDialog, setDeleteProductDialog] = useState(false);
  const [deleteProductsDialog, setDeleteProductsDialog] = useState(false);
  const [product, setProduct] = useState(emptyItem);
  const [selectedProducts, setSelectedProducts] = useState(null);
  const [submitted, setSubmitted] = useState(false);
  const [globalFilter, setGlobalFilter] = useState(null);
  const toast = useRef(null);
  const dt = useRef(null);

  const openNew = () => {
    setProduct(emptyItem);
    setSubmitted(false);
    setProductDialog(true);
  };

  const hideDialog = () => {
    setSubmitted(false);
    setProductDialog(false);
  };

  const hideDeleteProductDialog = () => {
    setDeleteProductDialog(false);
  };

  const hideDeleteProductsDialog = () => {
    setDeleteProductsDialog(false);
  };

  const saveProduct = () => {
    setSubmitted(true);
    if (product.productName.trim()) {
      let _product = { ...product };
      if (product.id) {
        updateData.mutate(
          {
            table_name: "LagerBestand",
            data: {
              id: product.id,
              barcode: product.barcode,
              product_name: product.productName,
              stock: product.quantity,
            },
          },
          {
            onSuccess: (response) => {
              toast.current.show({
                severity: "success",
                summary: "Successful",
                detail: "Entry Updated",
                life: 3000,
              });
            },
          }
        );
      } else {
        _product.orderDate = moment(_product.orderDate).format("YYYY-MM-DD");
        toast.current.show({
          severity: "success",
          summary: "Successful",
          detail: "Entry Created",
          life: 3000,
        });
      }
      // setProducts(_products);
      setProductDialog(false);
      setProduct(emptyItem);
    }
  };

  const editProduct = (product) => {
    setProduct({ ...product });
    setProductDialog(true);
  };

  const confirmDeleteProduct = (product) => {
    setProduct(product);
    setDeleteProductDialog(true);
  };

  const deleteProduct = () => {
    // let _products = products.filter(val => val.id !== product.id);
    deleteData.mutate(
      {
        table_name: "LagerBestand",
        data: {
          id: product.id,
        },
      },
      {
        onSuccess: (response) => {
          toast.current.show({
            severity: "success",
            summary: "Successful",
            detail: "Entry Deleted",
            life: 3000,
          });
          setDeleteProductDialog(false);
          setProduct(emptyItem);
        },
      }
    );
  };

  const exportCSV = () => {
    dt.current.exportCSV();
  };

  const confirmDeleteSelected = () => {
    setDeleteProductsDialog(true);
  };

  const deleteSelectedProducts = () => {
    let _products = products.filter((val) => !selectedProducts.includes(val));
    setProducts(_products);
    setDeleteProductsDialog(false);
    setSelectedProducts(null);
    toast.current.show({
      severity: "success",
      summary: "Successful",
      detail: "Products Deleted",
      life: 3000,
    });
  };
  const onInputChange = (e, name) => {
    const val = (e.target && e.target.value) || "";
    let _product = { ...product };
    _product[`${name}`] = val;
    setProduct(_product);
  };

  const onInputNumberChange = (e, name) => {
    const val = e.value || 0;
    let _product = { ...product };
    _product[`${name}`] = val;

    setProduct(_product);
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <Button
          icon="pi pi-pencil"
          className="p-button-rounded p-button-success mr-2"
          onClick={() => editProduct(rowData)}
        />
        <Button
          icon="pi pi-trash"
          className="p-button-rounded p-button-danger"
          onClick={() => confirmDeleteProduct(rowData)}
        />
      </React.Fragment>
    );
  };

  const header = (
    <div className="flex flex-column md:flex-row md:align-items-center justify-content-between">
      <span className="p-input-icon-left w-full md:w-auto">
        <i className="pi pi-search" />
        <InputText
          type="search"
          onInput={(e) => setGlobalFilter(e.target.value)}
          placeholder="Search..."
          className="w-full lg:w-auto"
        />
      </span>
      <div className="mt-3 md:mt-0 flex justify-content-end">
        <Button
          icon="pi pi-plus"
          className="mr-2 p-button-rounded"
          onClick={openNew}
          tooltip="New"
          tooltipOptions={{ position: "bottom" }}
        />
        <Button
          icon="pi pi-trash"
          className="p-button-danger mr-2 p-button-rounded"
          onClick={confirmDeleteSelected}
          disabled={!selectedProducts || !selectedProducts.length}
          tooltip="Delete"
          tooltipOptions={{ position: "bottom" }}
        />
        <Button
          icon="pi pi-upload"
          className="p-button-help p-button-rounded"
          onClick={exportCSV}
          tooltip="Export"
          tooltipOptions={{ position: "bottom" }}
        />
      </div>
    </div>
  );
  const productDialogFooter = (
    <React.Fragment>
      <Button
        label="Cancel"
        icon="pi pi-times"
        className="p-button-text"
        onClick={hideDialog}
      />
      <Button label="Save" icon="pi pi-check" onClick={saveProduct} />
    </React.Fragment>
  );

  const deleteProductDialogFooter = (
    <React.Fragment>
      <Button
        label="No"
        icon="pi pi-times"
        className="p-button-text"
        onClick={hideDeleteProductDialog}
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        className="p-button-text"
        onClick={deleteProduct}
      />
    </React.Fragment>
  );

  const deleteProductsDialogFooter = (
    <React.Fragment>
      <Button
        label="No"
        icon="pi pi-times"
        className="p-button-text"
        onClick={hideDeleteProductsDialog}
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        className="p-button-text"
        onClick={deleteSelectedProducts}
      />
    </React.Fragment>
  );
  let formattedData;
  if (tableData?.data) {
    //   // Convert the raw data into an array of objects
    formattedData = tableData.data?.map((row) => ({
      id: row[0],
      barcode: row[1],
      productName: row[2],
      quantity: row[3],
      date: new Date(row[4]).toLocaleDateString(),
    }));
  }

  // Define columns for the DataTable
  const columns = [
    { field: "id", header: "ID" },
    { field: "barcode", header: "Barcode" },
    { field: "productName", header: "Product Name" },
    { field: "quantity", header: "Quantity" },
    { field: "date", header: "Date" },
  ];

  return (
    <div>
      <div className="datatable-crud-demo surface-card p-4 border-round shadow-2">
        <Toast ref={toast} />

        <div className="text-3xl text-800 font-bold mb-4">
          Lager Bestand Portal
        </div>

        <DataTable
          value={formattedData}
          selection={selectedProducts}
          onSelectionChange={(e) => setSelectedProducts(e.value)}
          dataKey="id"
          paginator
          rows={10}
          rowsPerPageOptions={[5, 10, 25]}
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
          globalFilter={globalFilter}
          header={header}
          responsiveLayout="scroll"
        >
          <Column
            selectionMode="multiple"
            headerStyle={{ width: "3rem" }}
            exportable={false}
          ></Column>
          {columns.map((column) => (
            <Column
              key={column.field}
              field={column.field}
              header={column.header}
              sortable
              style={{ minWidth: "12rem" }}
            />
          ))}
          <Column
            body={actionBodyTemplate}
            exportable={false}
            style={{ minWidth: "8rem" }}
          ></Column>
        </DataTable>

        <Dialog
          visible={productDialog}
          breakpoints={{ "960px": "75vw", "640px": "100vw" }}
          style={{ width: "40vw" }}
          header="Lager Bestand"
          modal
          className="p-fluid"
          footer={productDialogFooter}
          onHide={hideDialog}
        >
          <div className="field">
            <div className="formgrid grid">
              <div className="field col-6">
                <label htmlFor="barcode">Barcode</label>
                <InputText
                  id="barcode"
                  value={product.barcode}
                  onChange={(e) => onInputChange(e, "barcode")}
                  required
                  autoFocus
                  className={classNames({
                    "p-invalid": submitted && !product.barcode,
                  })}
                />
                {submitted && !product.barcode && (
                  <small className="p-error">Barcode is required.</small>
                )}
              </div>
            </div>
          </div>

          <div className="field">
            <div className="formgrid grid">
              <div className="field col-12">
                <label htmlFor="productName">Product Name</label>
                <InputText
                  id="productName"
                  value={product.productName}
                  onChange={(e) => onInputChange(e, "productName")}
                  required
                  autoFocus
                  className={classNames({
                    "p-invalid": submitted && !product.productName,
                  })}
                />
                {submitted && !product.productName && (
                  <small className="p-error">Name is required.</small>
                )}
              </div>
            </div>
          </div>
          <div className="formgrid grid">
            <div className="field col">
              <label htmlFor="Quantity">Quantity</label>
              <InputNumber
                id="quantity"
                value={product.quantity}
                onValueChange={(e) => onInputNumberChange(e, "quantity")}
              />
            </div>
          </div>
        </Dialog>

        <Dialog
          visible={deleteProductDialog}
          style={{ width: "450px" }}
          header="Confirm"
          modal
          footer={deleteProductDialogFooter}
          onHide={hideDeleteProductDialog}
        >
          <div className="flex align-items-center justify-content-center">
            <i
              className="pi pi-exclamation-triangle mr-3"
              style={{ fontSize: "2rem" }}
            />
            {product && (
              <span>
                Are you sure you want to delete <b>{product.name}</b>?
              </span>
            )}
          </div>
        </Dialog>

        <Dialog
          visible={deleteProductsDialog}
          style={{ width: "450px" }}
          header="Confirm"
          modal
          footer={deleteProductsDialogFooter}
          onHide={hideDeleteProductsDialog}
        >
          <div className="flex align-items-center justify-content-center">
            <i
              className="pi pi-exclamation-triangle mr-3"
              style={{ fontSize: "2rem" }}
            />
            {product && (
              <span>Are you sure you want to delete the selected Entries?</span>
            )}
          </div>
        </Dialog>
      </div>
    </div>
  );
}

export default LagerBestandPortal;
