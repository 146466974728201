import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import axios, { AxiosResponse } from 'axios';
import keycloak from "../../keycloak";
import moment from "moment";

const API_URL = 'https://app-membership.onrender.com';

//const API_URL = "http://127.0.0.1:8000"


export const getMembersData = async () => {
  return axios
    .get(`${API_URL}/getmembers`, {
      headers: {
        Authorization: `Bearer ${keycloak.token}`,
      },
    })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};

export const useGetMembersData = () => {
  const queryFn = async (): Promise<any> => {
    const tableData = await getMembersData();
    return tableData;
  };
  return useQuery(['get-members-data'],
    queryFn, 
  );

};

export const createMember = async (requestBody: any) => {
 
  return axios
    .post(`${API_URL}/new`, requestBody, {
      headers: {
        Authorization: `Bearer ${keycloak.token}`,
        
      },
    })
    .then(({ data: newsubs }) => {
      return newsubs
    })
    .catch((error) => {
      throw error
    })
}

export const useCreateMember = () => {
  const queryClient = useQueryClient();
  return useMutation(createMember, {
    onSuccess: () => {
      queryClient.invalidateQueries(['get-members-data']);
    },
  })
}


export const updateMember = async (id:any, requestData:any) => {
  console.log(id)
  console.log(requestData)
  try {
    const response = await axios.put(`${API_URL}/updatemember?id=${id}`, requestData, {
      headers: {
        Authorization: `Bearer ${keycloak.token}`,
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const useUpdateMember = () => {
  const queryClient = useQueryClient();
  return useMutation<AxiosResponse<any, any>, Error, { id: any, data: any }>(
    ({ id, data }) => updateMember(id, data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['get-members-data']);
      },
    }
  );
}

export const deleteMember = async (id:any) => {
  try {
    const response = await axios.delete(`${API_URL}/deletemember?id=${id}`, {
      headers: {
        Authorization: `Bearer ${keycloak.token}`,
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};


export const useDeleteMember = () => {
  const queryClient = useQueryClient();
  return useMutation(deleteMember, {
    onSuccess: () => {
      queryClient.invalidateQueries(['get-members-data']);
    },
  })
}

export const sendEmail = async (requestBody: any) => {
 
  return axios
    .post(`${API_URL}/send_email`, requestBody, {
      headers: {
        Authorization: `Bearer ${keycloak.token}`,
        
      },
    })
    .then(({ data: newsubs }) => {
      return newsubs
    })
    .catch((error) => {
      throw error
    })
}

export const useSendEmail = () => {
  const queryClient = useQueryClient();
  return useMutation(sendEmail, {
    onSuccess: () => {
      queryClient.invalidateQueries(['get-members-data']);
    },
  })
}

export const createfileUpload = async (requestBody: any) => {
  const formData = new FormData();
  formData.append('file', requestBody.file);

  try {
    const response = await axios.post(`${API_URL}/upload`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${keycloak.token}`
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export const useCreateFileUpload = () => {
  return useMutation(createfileUpload)
}

export const sendNewsletter = async (requestBody:any) => {
  console.log(keycloak.token)
  const response = await axios.post(`${API_URL}/send_newsletter`, requestBody, {
    headers: {
      Authorization: `Bearer ${keycloak.token}`,
    },
  });
  return response.data;
};


export const useSendNewsletter= () => {
return useMutation(sendNewsletter)

}

export const sendTestNewstletter = async (requestBody:any) => {
  console.log(requestBody)
  console.log(keycloak.token)
  const response = await axios.post(`${API_URL}/send_test_newsletter`, requestBody, {
    headers: {
      Authorization: `Bearer ${keycloak.token}`,
    },
  });
  console.log(response)
  return response.data;
};


export const useTestSendNewsletter= () => {
  return useMutation(sendTestNewstletter)

}

export const sendVeggiesNewsletter = async (requestBody:any) => {
  console.log(keycloak.token)
  const response = await axios.post(`${API_URL}/send_veggies_newsletter`, requestBody, {
    headers: {
      Authorization: `Bearer ${keycloak.token}`,
    },
  });
  return response.data;
};


export const useSendVeggiesNewsletter= () => {
  return useMutation(sendVeggiesNewsletter)

}

export const sendTestVeggiesNewstletter = async (requestBody:any) => {
  console.log(requestBody)
  console.log(keycloak.token)
  const response = await axios.post(`${API_URL}/send_test_veggies_newsletter`, requestBody, {
    headers: {
      Authorization: `Bearer ${keycloak.token}`,
    },
  });
  console.log(response)
  return response.data;
};


export const useTestSendVeggiesNewsletter= () => {
  return useMutation(sendTestVeggiesNewstletter)

}
////////
export const sendFestiveNewsletter = async (requestBody:any) => {
  console.log(keycloak.token)
  const response = await axios.post(`${API_URL}/send_festive_newsletter`, requestBody, {
    headers: {
      Authorization: `Bearer ${keycloak.token}`,
    },
  });
  return response.data;
};


export const useSendFestiveNewsletter= () => {
  return useMutation(sendFestiveNewsletter)

}

export const sendTestFestiveNewstletter = async (requestBody:any) => {
  console.log(requestBody)
  console.log(keycloak.token)
  const response = await axios.post(`${API_URL}/send_test_festive_newsletter`, requestBody, {
    headers: {
      Authorization: `Bearer ${keycloak.token}`,
    },
  });
  console.log(response)
  return response.data;
};


export const useSendTestFestiveNewsletter= () => {
  return useMutation(sendTestFestiveNewstletter)

}
//////
export const fetchExpiringProducts = async () => {
  const { data } = await axios.get(`${API_URL}/expiring-products`, {
    headers: {
      Authorization: `Bearer ${keycloak.token}`,
    },
  });
  return data;
};

export const approveProduct = async (productId:any, status:any) => {
  const response = await axios.post(
      `${API_URL}/approve-product/${productId}`,
      { status }, // Include the status in the request body
      {
        headers: {
          Authorization: `Bearer ${keycloak.token}`, // Authorization header
          'Content-Type': 'application/json' // Ensure the content type is set to JSON
        }
      }
  );

  return response.data;
};

export const fetchInventory = async (filters:any) => {
  const params = {
    start_date: filters.startDate ? moment(filters.startDate).format('YYYY-MM-DD') : undefined,
    end_date: filters.endDate ? moment(filters.endDate).format('YYYY-MM-DD') : undefined,
  };
  console.log(params);
  const { data } = await axios.get(`${API_URL}/inventory`, {
    params,
    headers: {
      Authorization: `Bearer ${keycloak.token}`,
    },
  });
  return data;
};