import { useState } from "react";
import { InputText } from "primereact/inputtext";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useTableData } from "./services/LagerBestandService";

function ExpiryStatus() {
  
  const tableData = useTableData("NewStock");
  const [globalFilter, setGlobalFilter] = useState(null);
  const [selectedProducts, setSelectedProducts] = useState(null);

    
  const columns = [
    { field: "id", header: "ID" },
    { field: "barcode", header: "Barcode" },
    { field: "productName", header: "Product Name" },
    { field: "quantity", header: "Quantity" },
    { field: "expirydate", header: "Expiry Date" },
    { field: "distributor", header: "Distributor" },
    { field: "date", header: "Uploaded Date" },
  ];

  const header = (
    <div className="flex flex-column md:flex-row md:align-items-center justify-content-between">
      <span className="p-input-icon-left w-full md:w-auto">
        <i className="pi pi-search" />
        <InputText
          type="search"
          onInput={(e) => setGlobalFilter(e.target.value)}
          placeholder="Search..."
          className="w-full lg:w-auto"
        />
      </span>
      <div className="mt-3 md:mt-0 flex justify-content-end">
        
      </div>
    </div>
  );

  let formattedData = [];
  if (tableData.data) {
    //   // Convert the raw data into an array of objects
    formattedData = tableData.data?.map((row) => ({
      id: row[0],
      barcode: row[1],
      productName: row[2],
      quantity: row[3],
      expirydate: row[4],
      distributor: row[6],
      date: new Date(row[5]).toLocaleDateString(),
    }));
  }

  // Get today's date for comparison
  const today = new Date();
  today.setHours(0, 0, 0, 0); // Set time to beginning of the day
  
  // Filter data based on expiry date
  const yesterdayData = formattedData.filter(item => {
    const expiryDate = new Date(item.expirydate);
    expiryDate.setHours(0, 0, 0, 0); // Set time to beginning of the day
    return expiryDate.getTime() === today.getTime() - 24 * 60 * 60 * 1000; // Expiry date is yesterday
  });

  const todayData = formattedData.filter(item => {
    const expiryDate = new Date(item.expirydate);
    expiryDate.setHours(0, 0, 0, 0); // Set time to beginning of the day
    return expiryDate.getTime() === today.getTime(); // Expiry date is today
  });

  const tomorrowData = formattedData.filter(item => {
    const expiryDate = new Date(item.expirydate);
    expiryDate.setHours(0, 0, 0, 0); // Set time to beginning of the day
    return expiryDate.getTime() === today.getTime() + 24 * 60 * 60 * 1000; // Expiry date is tomorrow
  });

  return ( 
    <div>
      <div className="datatable-crud-demo surface-card p-4 border-round shadow-2">
      <div className="text-3xl text-800 font-bold mb-4">Yesterday's Expiry</div>
          <DataTable
            value={yesterdayData}
            selection={selectedProducts}
            onSelectionChange={(e) => setSelectedProducts(e.value)}
            dataKey="id"
            paginator
            rows={10}
            rowsPerPageOptions={[5, 10, 25]}
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
            globalFilter={globalFilter}
            header={header}
            responsiveLayout="scroll"
          >
          <Column
            headerStyle={{ width: "3rem" }}
            exportable={false}
          ></Column>
          {columns.map((column) => (
            <Column
              key={column.field}
              field={column.field}
              header={column.header}
              sortable
              style={{ minWidth: "12rem" }}
            />
          ))}
          </DataTable>
          <div className="text-3xl text-800 font-bold mb-4">Today's Expiry</div>
          <DataTable
            value={todayData}
            selection={selectedProducts}
            onSelectionChange={(e) => setSelectedProducts(e.value)}
            dataKey="id"
            paginator
            rows={10}
            rowsPerPageOptions={[5, 10, 25]}
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
            globalFilter={globalFilter}
            header={header}
            responsiveLayout="scroll"
          >
          <Column
            headerStyle={{ width: "3rem" }}
            exportable={false}
          ></Column>
          {columns.map((column) => (
            <Column
              key={column.field}
              field={column.field}
              header={column.header}
              sortable
              style={{ minWidth: "12rem" }}
            />
          ))}
          </DataTable>
          <div className="text-3xl text-800 font-bold mb-4">Tomorrow's Expiry</div>
          <DataTable
            value={tomorrowData}
            selection={selectedProducts}
            onSelectionChange={(e) => setSelectedProducts(e.value)}
            dataKey="id"
            paginator
            rows={10}
            rowsPerPageOptions={[5, 10, 25]}
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
            globalFilter={globalFilter}
            header={header}
            responsiveLayout="scroll"
          >
          <Column
            headerStyle={{ width: "3rem" }}
            exportable={false}
          ></Column>
          {columns.map((column) => (
            <Column
              key={column.field}
              field={column.field}
              header={column.header}
              sortable
              style={{ minWidth: "12rem" }}
            />
          ))}
          </DataTable>
      </div>
      
    </div> 
  );
}


export default ExpiryStatus;