import { useState } from "react";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import { TabMenu } from "primereact/tabmenu";
import { MenuItem } from "primereact/menuitem";
import NewsletterForm from "./newsletter"
// import StockManagementPortal from "./stockmanagementportal";
// import ZAbschlagManagementPortal from "./zabschlagmanagement";
import LagerBestandPortal from "./lagerbestand";
import ExpiredProducts from "./expiredproducts";
import MembershipPortal from "./Membership";
import DamagedProducts from "./damagedproducts";
import NewStock from "./newStock";
import IMProducts from "./IMProducts";
import keycloak from "../keycloak";
import { Navigate } from "react-router-dom";
import ZAbschlagManagementPortal from "./zAbschlag"
import FileUploader from "./stockUpload"
import Inventory from "./inventory";
import ExpiryStatus from "./expiryStatus";
import ExpiringProducts from "./expiringProducts";

function NavBar() {
  const [activeTabIndex, setActiveTabIndex] = useState<number>(0);
  const handleLogout = () => {
    keycloak.logout(); // Call logout method
    <Navigate to="/" />;
  };

  const items: MenuItem[] = [
    {
      label: "Home",
      icon: "pi pi-fw pi-home",
      command: () => setActiveTabIndex(0),
    },
    {
      label: "SaleEntry",
      icon: "pi pi-fw pi-pencil",
      command: () => setActiveTabIndex(1),
    },
    {
      label: "Membership",
      icon: "pi pi-fw pi-calendar",
      command: () => setActiveTabIndex(2),
    },
    {
      label: "Lager Stock",
      icon: "pi pi-fw pi-pencil",
      command: () => setActiveTabIndex(3),
    },
    {
      label: "IM Stock",
      icon: "pi pi-fw pi-pencil",
      command: () => setActiveTabIndex(4),
    },
    {
      label: "Expired Products",
      icon: "pi pi-fw pi-pencil",
      command: () => setActiveTabIndex(5),
    },
    {
      label: "Damaged Products",
      icon: "pi pi-fw pi-pencil",
      command: () => setActiveTabIndex(6),
    },
    {
      label: "New Stock Incomings",
      icon: "pi pi-fw pi-pencil",
      command: () => setActiveTabIndex(7),
    },
    {
      label: "Newsletter - Automation",
      icon: "pi pi-fw pi-pencil",
      command: () => setActiveTabIndex(8),
    },
    {
      label: "Inventory",
      icon: "pi pi-fw pi-pencil",
      command: () => setActiveTabIndex(9),
    },
    {
      label: "Expiry Status",
      icon: "pi pi-fw pi-pencil",
      command: () => setActiveTabIndex(10),
    },
    {
      label: "ExpiringProducts",
      icon: "pi pi-fw pi-pencil",
      command: () => setActiveTabIndex(11),
    },
    { label: "Logout", icon: "pi pi-fw pi-cog", command: () => handleLogout() },
  ];

  const renderComponentByTab = () => {
    switch (activeTabIndex) {
      case 0:
        return <FileUploader />;
      case 1:
        return <ZAbschlagManagementPortal />;
      case 2:
        return <MembershipPortal />;
      case 3:
        return <LagerBestandPortal />;
      case 4:
        return <IMProducts />;
      case 5:
        return <ExpiredProducts />;
      case 6:
        return <DamagedProducts />;
      case 7:
        return <NewStock />;
      case 8:
        return <NewsletterForm />;
      case 9:
        return <Inventory />;
      case 10:
        return <ExpiryStatus />;
      case 11:
        return <ExpiringProducts />;
      // // Add cases for other tabs and their corresponding components
      // case 3:
      //   return <LagerBestandPortal />;
      default:
        return null;
    }
  };

  return (
    <div className="card">
      <TabMenu
        model={items}
        activeIndex={activeTabIndex}
        onTabChange={(e) => setActiveTabIndex(e.index)}
      />
      {renderComponentByTab()}
    </div>
  );
}

export default NavBar;
