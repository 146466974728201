import logo from "../assests/logo.png";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import { Button } from "primereact/button";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import NavBar from "./NavBar";
import keycloak from "../keycloak";
import { Navigate} from "react-router-dom";


function Header() {

  const handleLogout = () => {
    keycloak.logout(); // Call logout method
    <Navigate to="/"/>
    
  };
  return (
    <div className="container mw-100 p-5">
      <div className="row py-12">
        <div className="col-4">
          <input
            type="image"
            className="bg-white shadow-lg rounded"
            src={logo}
            width={300}
            height={100}
            alt="lotus"
            onClick={() => window.open("/", "_self")}
          />
          <Button
            className="absolute right-auto left top height-10 mt-4 ml-8"
            onClick={handleLogout}
            label="Logout"
            icon="pi pi-sign-out"
          />
        </div>
        <h1>Admin Utility Manager</h1>
        <NavBar />
      </div>
    </div>
  );
}

export default Header;
