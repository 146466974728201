import Header from "./components/Header";
import Login from "./components/Login";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import React from "react";
import { useKeycloak } from "@react-keycloak/web";

interface PrivateRouteProps {
  component: React.FC;
}

export function PrivateRoute({ component: Component }: PrivateRouteProps) {
  const { keycloak } = useKeycloak();
  const location = useLocation();

  return keycloak?.authenticated ? (
    <Component />
  ) : (
    <Navigate to="/" state={{ from: location }} />
  );
}

function App() {
  return (
    <>
      <Routes>
        {/* <Route path="/" element={<LandingPage />} /> */}
        <Route path="*" element={<Login />} />
        <Route
          path="/portal"
          element={<PrivateRoute component={Header} />}
        ></Route>
      </Routes>
    </>
  );
}

export default App;
