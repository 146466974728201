// InventoryTable.js
import React, { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Calendar } from 'primereact/calendar';
import { Button } from 'primereact/button';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { InputText } from 'primereact/inputtext';
import { ProgressSpinner } from 'primereact/progressspinner';
import {fetchInventory} from "./services/DataServices";

const InventoryTable = () => {
    //const keycloak = useContext(KeycloakContext);
    const [filters, setFilters] = useState({
        startDate: null,
        endDate: null,
    });

    const [triggerFetch, setTriggerFetch] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);

    const { data: inventory, error } = useQuery(
        ['inventory', filters],
        () => fetchInventory(filters),
        {
            enabled: triggerFetch,
            keepPreviousData: true,
            onSuccess: () => setIsLoading(false),
        }
    );

    const onFilterChange = (e, field) => {
        const value = e.target.value || e.value;
        setFilters({ ...filters, [field]: value });
    };

    const fetchData = () => {
        setTriggerFetch(true);
        setIsLoading(true);
    };

    const header = (
        <div className="flex flex-column md:flex-row md:align-items-center justify-content-between">
            <span className="p-input-icon-left w-full md:w-auto">
                <i className="pi pi-search" />
                <InputText
                    type="search"
                    onInput={(e) => setGlobalFilter(e.target.value)}
                    placeholder="Search..."
                    className="w-full lg:w-auto"
                />
            </span>
        </div>
    );

    return (
        <div>
            <div className="flex flex-column md:flex-row md:align-items-center justify-content-between p-3">
                <div className="mt-3 md:mt-0 flex justify-content-end">
                    <div className="mt-2 text-center">
                        <span className="mr-2">Start Date:</span>
                    </div>
                    <Calendar
                        value={filters.startDate}
                        onChange={(e) => onFilterChange(e, 'startDate')}
                        showIcon
                        className="mr-2"
                        placeholder="Start Date"
                    />
                    <div className="mt-2 text-center">
                        <span className="mr-2">End Date:</span>
                    </div>
                    <Calendar
                        value={filters.endDate}
                        onChange={(e) => onFilterChange(e, 'endDate')}
                        showIcon
                        className="mr-2"
                        placeholder="End Date"
                    />
                    <Button label="Fetch Data" icon="pi pi-search" onClick={fetchData} />
                </div>
            </div>

            {isLoading && (
                <div style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'rgba(255, 255, 255, 0.7)',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    zIndex: 9999,
                }}>
                    <ProgressSpinner style={{ width: '50px', height: '50px' }} strokeWidth="4" />
                </div>
            )}
            {!isLoading && error && (
                <div>Error fetching inventory data</div>
            )}
            {!isLoading && !error && (
                <DataTable
                    value={inventory}
                    paginator
                    rows={10}
                    rowsPerPageOptions={[5, 10, 25, 30]}
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                    globalFilter={globalFilter}
                    header={header}
                    responsiveLayout="scroll"
                >
                    <Column field="id" header="ID" sortable />
                    <Column field="sku" header="SKU" sortable />
                    <Column field="item_name" header="Item Name" sortable />
                    <Column field="price" header="Price" sortable />
                    <Column field="tax" header="Tax" sortable />
                    <Column field="quantity" header="Quantity" sortable />
                    <Column field="start_quantity" header="Start Quantity" sortable />
                    <Column field="end_quantity" header="End Quantity" sortable />
                    <Column field="stock_difference" header="Stock Difference" sortable />
                    <Column field="category" header="Category" sortable />
                    <Column field="log_date" header="Log Date" sortable />
                </DataTable>
            )}
        </div>
    );
};

export default InventoryTable;
